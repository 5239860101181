import React from 'react';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import { Row, Col,Carousel } from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
function currency(num){
  num = parseFloat(num);
  if (!Number.isNaN(num) && num !== "undefined" && num !== null) {
      return num.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  }else {
      return '-';
  }
}
function Content1(props) {
  const { ...tagProps } = props;
  const { dataSource,producto, isMobile } = tagProps;
  delete tagProps.dataSource;
  delete tagProps.isMobile;

  const animType = {
    queue: isMobile ? 'bottom' : 'right',
    one: isMobile
      ? {
          scaleY: '+=0.3',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        }
      : {
          x: '-=30',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        },
  };
  const contentStyle =  {
    margin: 0,
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
  };
  return (
    <div {...tagProps} {...dataSource.wrapper}>
      <div {...dataSource.OverPack} component={Row}>
        <h3 key="h1" {...dataSource.title}>
            {producto.titulo}
        </h3>

        <Carousel autoplay={true}>
        {producto && producto.imagenes.map((data, index) => {
                return   (
                  <div key={"div"+index}>
                  <img key={index} src={"https://elcandil.ipsistemas.com.ar"+data.url} width="100%" alt= {producto.titulo} />
                  </div>
                )        

          })}

        </Carousel> 

          <p key="p1" >
            <br/>
            Código <b>{producto.codigo}</b>
            <br/>
            <h2>$ { currency( producto.cantidadstock)}</h2>
            <br/>
            {producto.descripcion}
          </p>

      </div>
    </div>
  );
}

export default Content1;
