import React from 'react';
import {  Switch, Route } from 'react-router-dom';
import Home from '../Home';
const Root = () => (

    <Switch>
      <Route exact path="/:id" component={Home}/>
    </Switch>

);
export default Root;