import React from 'react';



export const Nav30DataSource = {
  wrapper: { className: 'header3 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header3-logo',
    children:
      'https://elcandil.ipsistemas.com.ar/images/elcandil/logo.png',
  },
  Menu: {
    className: 'header3-menu',
    children: [],
  },
  mobileMenu: { className: 'header3-mobile-menu' },
};
export const Content10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 14, xs: 24 },
  img: {
    children: 'https://elcandil.ipsistemas.com.ar/comprobantes/mifactura/imagenes/3c86cab3a0b547da7ac59f948c717b76.jpg',
    
  },
  textWrapper: { className: 'content1-text', md: 10, xs: 24 },
  title: { className: 'content1-title', children: 'Colgante  Chino chapa blco' },
  content: {
    className: 'content1-content',
    children:
      'Construido en chapa pintura horno',
  },
};
