import 'isomorphic-fetch';

const URL_API='https://elcandil.ipsistemas.com.ar/getjson.php?controller=prod&do=findproductos&idproducto=';

export const api = {
    prod: {
      async get(id) {
        console.log(URL_API+id)
          const response = await fetch(URL_API+id, {
              method: 'GET',
              headers: {
                  'Content-Type': 'application/json',
              }
          });
          const responseData = await response.json();
          return responseData;
      }
    }
};