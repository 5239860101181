/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';
import {api} from './api';
import Content1 from './Content1';
import Nav3 from './Nav3';
import { Nav30DataSource } from './data.source';
import './less/antMotionStyle.less';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location = {} } = typeof window !== 'undefined' ? window : {};

export default class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile,
      show: false, // 如果不是 dva 2.0 请删除
      Content10DataSource : {
        wrapper: { className: 'home-page-wrapper content1-wrapper' },
        OverPack: { className: 'home-page content1', playScale: 0.3 },
        imgWrapper: { className: 'content1-img', md: 14, xs: 24 },
        img: {
          children: 'http://elcandil.ipsistemas.com.ar/comprobantes/mifactura/imagenes/3c86cab3a0b547da7ac59f948c717b76.jpg',
        },
        textWrapper: { className: 'content1-text', md: 10, xs: 24 },
        title: { className: 'content1-title', children: 'Colgante  Chino chapa blco' },
        content: {
          className: 'content1-content',
          children:
            'Construido en chapa pintura horno',
        },
      }
    }
  }


  

  async componentDidMount() {
    
    const response = await api.prod.get(this.props.match.params.id);


    if (response){
      this.setState({ producto: response ,show:true});
    }
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
    /* 如果不是 dva 2.0 请删除 start */
    if (location.port) {
      // 样式 build 时间在 200-300ms 之间;
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }
    /* 如果不是 dva 2.0 请删除 end */
  }
  render() {
    const children = [
      <Nav3
      id="Nav3_0"
      key="Nav3_0"
      dataSource={Nav30DataSource}
      isMobile={this.state.isMobile}
    />,
      <Content1
        id="Content1_0"
        key="Content1_0"
        dataSource={this.state.Content10DataSource}
        producto={this.state.producto}
        isMobile={this.state.isMobile}
      />,
    ];
    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        {/* 如果不是 dva 2.0 替换成 {children} start */}
        {this.state.show && children}
        {/* 如果不是 dva 2.0 替换成 {children} end */}
      </div>
    );
  }
}
